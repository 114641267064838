// import './bootstrap';

import '@/icons/bwi/all-bwi.css'

import '@fontsource/dm-sans/latin-400-italic.css'
import '@fontsource/dm-sans/latin-400.css'
import '@fontsource/dm-sans/latin-500.css'
import '@fontsource/dm-sans/latin-700.css'

import '../css/app.css'

import { createInertiaApp, router } from '@inertiajs/react'
import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import ErrorBoundary from '@/components/error-boundary.js'

import * as Sentry from '@sentry/react'

declare global {
  interface Window {
    _hsq: any[]
    HubSpotConversations?: {
      widget: {
        refresh: () => void
      }
    }
  }
}

createInertiaApp({
  resolve: (name: string) => resolvePageComponent(`./pages/${name}.tsx`, import.meta.glob('./pages/**/*.tsx')),
  setup({ el, App, props }: { el: HTMLElement; App: any; props: any }) {
    const { DEV, VITE_SENTRY_DSN, VITE_SENTRY_SAMPLE_RATE, VITE_SENTRY_DEBUG } = import.meta.env

    if (VITE_SENTRY_DSN) {
      Sentry.init({
        dsn: VITE_SENTRY_DSN,
        sampleRate: VITE_SENTRY_SAMPLE_RATE,
        debug: VITE_SENTRY_DEBUG === 'true',
      })
    }

    if (!DEV) {
      router.on('navigate', (event) => {
        if (typeof window === 'undefined') return

        const { url } = event.detail.page
        const _hsq = (window._hsq = window._hsq || [])
        _hsq.push(['setPath', url])
        _hsq.push(['trackPageView'])

        if (window.HubSpotConversations) {
          window.HubSpotConversations.widget.refresh()
        }
      })
    }

    // this code must be same as block in ssr.tsx
    const app = (
      <ErrorBoundary>
        <App {...props} />
      </ErrorBoundary>
    )
    // similarities end here

    if (DEV) {
      createRoot(el).render(app)
    } else {
      hydrateRoot(el, app)
    }
  },
})
