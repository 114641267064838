import React from 'react'
import * as Sentry from '@sentry/browser'
import LogoHorizontal from '@/icons/logo-horizontal.svg'

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="bg-indigoBlue min-h-screen">
          <a href="/">
            <div className="bg-primaryBlue py-3 px-5 lg:px-9 lg:py-5">
              <div className="w-36 lg:w-44">
                <LogoHorizontal className="text-white" />
              </div>
            </div>
          </a>

          <div className="xl:w-[1200px] mx-auto py-14 px-5 lg:px-9">
            <div className="flex lg:justify-end bg-[url('https://images.ctfassets.net/7rncvj1f8mw7/3hFwAbRp0pdfStyL1jNUXW/3713a16599af668b2d36d4472a1c6540/security-cellar-new.svg')] bg-contain bg-no-repeat bg-center  min-h-[360px] lg:min-h-[690px] items-center">
              <div className="text-white lg:w-7/12">
                <p className="text-2xl lg:text-4xl mb-0">Oops.</p>
                <h1 className="my-6">Something went wrong!</h1>
                <p className="text-2xl lg:text-4xl">
                  It's not your fault its ours, we're working to fix the issue. Please try again later.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
